import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import "./game.css";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { allGames } from "../../allgames";

function Slot() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [isLoading, setLoading] = useState(false);
    const [game, setGame] = useState(null);
    const [title, setTitle] = useState(null);
    const [isShow, setShow] = useState(false);
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    }, []);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            setProfile(res.data.data);
            localStorage.setItem("profile", JSON.stringify(res.data.data));
        });
        handleOptionClick(0, 1026);
    }, []);
    function launchGame(params) {
        if (profile === null) {
            navigate("/login");
            return;
        }
        if (isLoading == true) return;
        const form = {
            ...params,
            username: profile?.username
        };
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/login`, form)
            .then((res) => {
                let url = res.data.data.url;
                if (form.title == "SportsBook") {
                    url = `https:${url}&lang=vi-vn&oddstyle=MY&theme=sbo&oddsmode=double&device=d`;
                } else if (form.title == "Casino") {
                    url = `https:${url}&locale=vi-vn&device=d&loginMode=1&productId=0`;
                } else if (form.title == "VirtualSports") {
                    url = `https:${url}&lang=vi-vn`;
                } else if (form.title == "SeamlessGame") {
                    url = `https:${url}&gpid=${params.gpid}&gameid=${params.gameId}&lang=vi-vn&device=d`;
                } else if (form.title == "ThirdPartySportsBook") {
                    url = `https:${url}&gpid=${params.gpid}&gameid=0&lang=vi-vn&device=d`;
                }
                window.location.href = url;
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.data.data.tokenVerificationData.access == false) {
                    swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error");
                    return;
                }
                //swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
            });
    }
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);

    const [activeOption, setActiveOption] = useState("0");
    const handleOptionClick = (option, provider) => {
        setActiveOption(option.toString());
        setGame(
            allGames.filter((game) => game.gameType === 2 && game.gameProviderId == provider && game.gameInfos[0]?.language != "ZH_CN" && game.supportedCurrencies.includes("VND"))
        );
    };
    return (
        <>
            <Header profile={profile} />
            {isLoading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="bg-slots">
                <div className="container">
                    <div className="tabframe" style={{ height: "auto" }}>
                        <div className={activeOption === "0" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick(0, "1026")}>
                            <img src={require("../../image/common/rich88.png")} style={{ padding: "5px" }} />
                            <p>Rich88</p>
                        </div>
                        <div className={activeOption === "1" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick(1, "1046")}>
                            <img src={require("../../image/common/fc.png")} style={{ padding: "5px" }} />
                            <p>FaChai</p>
                        </div>
                        <div className={activeOption === "2" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick(2, "1044")}>
                            <img src={require("../../image/common/ps.png")} style={{ padding: "5px" }} />
                            <p>PlayStar</p>
                        </div>
                        <div className={activeOption === "3" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick(3, "35")}>
                            <img src={require("../../image/common/pg.png")} style={{ padding: "5px" }} />
                            <p>PGSoft</p>
                        </div>
                        <div className={activeOption === "4" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick(4, "16")}>
                            <img src={require("../../image/common/fkg.png")} style={{ padding: "5px" }} />
                            <p>FunkyGames</p>
                        </div>
                        <div className={activeOption === "5" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick(5, "1031")}>
                            <img src={require("../../image/common/hbn.png")} style={{ padding: "5px" }} />
                            <p>Habanero</p>
                        </div>
                        <div className={activeOption === "6" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick(6, "39")} style={{ marginTop: "10px" }}>
                            <img src={require("../../image/common/rtg.png")} style={{ padding: "5px" }} />
                            <p>RedTiger</p>
                        </div>
                        <div className={activeOption === "7" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick(7, "1034")} style={{ marginTop: "10px" }}>
                            <img src={require("../../image/common/ap.png")} style={{ padding: "5px" }} />
                            <p>AdvantPlay</p>
                        </div>
                        <div className={activeOption === "8" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick(8, "29")} style={{ marginTop: "10px" }}>
                            <img src={require("../../image/common/icon-mg.png")} style={{ padding: "5px" }} />
                            <p>MicroGaming</p>
                        </div>
                        <div className={activeOption === "9" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick(9, "1040")} style={{ marginTop: "10px" }}>
                            <img src={require("../../image/common/icon-nlm.png")} style={{ padding: "5px" }} />
                            <p>City</p>
                        </div>
                    </div>
                    <div className="grid-6">
                        {game != null &&
                            game.map((item) => (
                                <div className="gameslot-item" onClick={() => launchGame({ title: "SeamlessGame", gameId: item.gameID, gpid: item.gameProviderId })}>
                                    <img alt={item.gameInfos[0]?.gameName} src={item.gameInfos[0]?.gameIconUrl} />
                                    <div className="gameslot-name">{item.gameInfos[0]?.gameName}</div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
}
export default Slot;
