import { Link, useNavigate } from "react-router-dom";
import "./home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper/core";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import Header from "../components/Header";

function Home() {
    const [profile, setProfile] = useState(null);
    const url = `${process.env.REACT_APP_API_URL}/auth/getUser`;
    SwiperCore.use([Autoplay]);
    const swiperParams = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        }
    };
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const navigate = useNavigate();
    useEffect(() => {
        axios
            .get(url, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
    }, []);
    const [loading, setLoading] = useState(false);
    if (url.length != 39) return;
    return (
        <>
            <Header profile={profile} />
            {loading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            {swiperParams && notify && (
                <div className="box-slide" style={{ overflow: "hidden" }}>
                    <Swiper {...swiperParams}>
                        {notify.map((item, index) => (
                            <>
                                {index > 1 && item.title !== "marquee" && item.title !== "popup" && item.isShow === true ? (
                                    <SwiperSlide>
                                        <img alt={item.title.replace("}", "").replace("{", "")} src={item.image} />
                                    </SwiperSlide>
                                ) : null}
                            </>
                        ))}
                    </Swiper>
                </div>
            )}
            <div className="bg-home">
                <div className="sec-game">
                    <div className="super-title">
                        <div className="super-title-left">
                            <img src="/images/left.png.avif" />
                        </div>
                        <div className="super-title-center">
                            <div className="super-title-big">TRÒ CHƠI HOT NHẤT</div>
                            <div className="super-title-small">GAME CỰC HOT, CHƠI CỰC ĐỈNH</div>
                        </div>
                        <div className="super-title-right">
                            <img src="/images/left.png.avif" style={{ transform: "rotateY(180deg)" }} />
                        </div>
                    </div>
                    <div className="container">
                        <div className="home-game">
                            <div className="home-game-item">
                                <Link to="/sicbo">
                                    <img src={require("../../image/common/mini.png")} />
                                </Link>
                            </div>
                            <div className="home-game-item">
                                <Link to="/casino">
                                    <img src={require("../../image/common/live.png")} />
                                </Link>
                            </div>
                            <div className="home-game-item">
                                <Link to="/sports">
                                    <img src={require("../../image/common/sp.png")} />
                                </Link>
                            </div>
                            <div className="home-game-item">
                                <Link to="/slots">
                                    <img src={require("../../image/common/dz.png")} />
                                </Link>
                            </div>
                            <div className="home-game-item">
                                <Link to="/pvp">
                                    <img src={require("../../image/common/qp.png")} />
                                </Link>
                            </div>
                            <div className="home-game-item">
                                <Link to="/lottery">
                                    <img src={require("../../image/common/cp.png")} />
                                </Link>
                            </div>
                            <div className="home-game-item">
                                <Link to="/fishing">
                                    <img src={require("../../image/common/by.png")} />
                                </Link>
                            </div>
                            <div className="home-game-item">
                                <Link to="/cockfighting">
                                    <img src={require("../../image/common/dj.png")} />
                                </Link>
                            </div>
                            <div className="home-game-item">
                                <Link to="/esports">
                                    <img src={require("../../image/common/esport.png")} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sec-adventure">
                    <div className="super-title">
                        <div className="super-title-left">
                            <img src="/images/left.png.avif" />
                        </div>
                        <div className="super-title-center">
                            <div className="super-title-big">NHỮNG TÍNH NĂNG</div>
                            <div className="super-title-small">VƯỢT TRỘI CỦA KS79</div>
                        </div>
                        <div className="super-title-right">
                            <img src="/images/left.png.avif" style={{ transform: "rotateY(180deg)" }} />
                        </div>
                    </div>
                    <ul class="container">
                        <li>
                            <img src={require("../../image/common/withdraw.png")} />
                            <h3>NẠP/RÚT SIÊU TỐC</h3>
                            <p>Đa dạng hình thức, đơn giản thao tác, xử lý nhanh chóng - Mọi giao dịch của quý khách sẽ được xử lý với tốc độ nhanh nhất.</p>
                        </li>
                        <li>
                            <img src={require("../../image/common/badge.png")} />
                            <h3>CÔNG BẰNG &amp; MINH BẠCH</h3>
                            <p>Cam kết trả thưởng nhanh chóng, kết quả được công bố minh bạch, rõ ràng, trực tiếp từ Nhà cung cấp và Ban tổ chức.</p>
                        </li>
                        <li>
                            <img src={require("../../image/common/safety.png")} />
                            <h3>AN TOÀN &amp; BẢO MẬT</h3>
                            <p>Mọi thông tin tại KS79 được bảo mật tuyệt đối bằng kết nối Blockchain. Cam kết an toàn tuyệt đối cho thông tin người chơi.</p>
                        </li>
                        <li>
                            <img src={require("../../image/common/contact.png")} />
                            <h3>HỖ TRỢ TRỰC TUYẾN 24/7</h3>
                            <p>Chuyên nghiệp, am hiểu, hỗ trợ xuyên suốt - KS79 giải đáp mọi thắc mắc mà quý khách hàng cần.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}
export default Home;
