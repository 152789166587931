import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import "./game.css";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";

function Pvp() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            setProfile(res.data.data);
            localStorage.setItem("profile", JSON.stringify(res.data.data));
        });
        handleOptionClick("0");
    }, []);
    const [activeOption, setActiveOption] = useState(null);
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    function launchGame(params) {
        if (profile === null) {
            navigate("/login");
            return;
        }
        if (isLoading == true) return;
        const form = {
            ...params,
            username: profile?.username
        };
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/login`, form)
            .then((res) => {
                let url = res.data.data.url;
                if (form.title == "SportsBook") {
                    url = `https:${url}&lang=vi-vn&oddstyle=MY&theme=sbo&oddsmode=double&device=d`;
                } else if (form.title == "Casino") {
                    url = `https:${url}&locale=vi-vn&device=d&loginMode=1&productId=0`;
                } else if (form.title == "VirtualSports") {
                    url = `https:${url}&lang=vi-vn`;
                } else if (form.title == "SeamlessGame") {
                    url = `https:${url}&gpid=${params.gpid}&gameid=${params.gameId}&lang=vi-vn&device=d`;
                } else if (form.title == "ThirdPartySportsBook") {
                    url = `https:${url}&gpid=${params.gpid}&gameid=0&lang=vi-vn&device=d`;
                }
                window.location.href = url;
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.data.data.tokenVerificationData.access == false) {
                    swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error");
                    return;
                }
                //swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
            });
    }
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);
    return (
        <>
            <Header profile={profile} />
            {isLoading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="bg-pvp">
                <div className="container">
                    <div className="tab_item" style={{ overflow: "unset", height: "500px" }}>
                        <div className="tabitem active">
                            <div className="content-box">
                                <img className="live-provider-img" src={require("../../image/common/kingpoker.png")} />
                                <a
                                    class="play-btn"
                                    onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1009" })}
                                    style={{ marginTop: "50px", display: "block" }}>
                                    PLAY NOW
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/common/kp-woman.png")} style={{ width: "108%", maxWidth: "108%", marginRight: "-50%" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Pvp;
