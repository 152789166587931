import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import "./game.css";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";

function Sports() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [isLoading, setLoading] = useState(false);
    const [game, setGame] = useState(null);
    const [title, setTitle] = useState(null);
    const [isShow, setShow] = useState(false);
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    }, []);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            setProfile(res.data.data);
            localStorage.setItem("profile", JSON.stringify(res.data.data));
        });
    }, []);
    function launchGame(params) {
        if (profile === null) {
            navigate("/login");
            return;
        }
        if (isLoading == true) return;
        const form = {
            ...params,
            username: profile?.username
        };
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/login`, form)
            .then((res) => {
                let url = res.data.data.url;
                if (form.title == "SportsBook") {
                    url = `https:${url}&lang=vi-vn&oddstyle=MY&theme=sbo&oddsmode=double&device=d`;
                } else if (form.title == "Casino") {
                    url = `https:${url}&locale=vi-vn&device=d&loginMode=1&productId=0`;
                } else if (form.title == "VirtualSports") {
                    url = `https:${url}&lang=vi-vn`;
                } else if (form.title == "SeamlessGame") {
                    url = `https:${url}&gpid=${params.gpid}&gameid=${params.gameId}&lang=vi-vn&device=d`;
                } else if (form.title == "ThirdPartySportsBook") {
                    url = `https:${url}&gpid=${params.gpid}&gameid=0&lang=vi-vn&device=d`;
                }
                window.location.href = url;
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.data.data.tokenVerificationData.access == false) {
                    swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error");
                    return;
                }
                //swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
            });
    }
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);


    return (
        <>
            <Header profile={profile} />
            {isLoading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="bg-sport">
                <div className="container">
                    <center>
                        <img src={require("../../image/common/title3.png")} />
                    </center>
                    <div className="menu-box-game" style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr", background: "#1f204b", border: "1px solid #6689ed", padding: "15px" }}>
                        <div className="box-game" style={{ margin: "0" }}>
                            <div className="box-game-image">
                                <img src={require("../../image/sbo.jpg")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ title: "SportsBook" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game" style={{ margin: "0" }}>
                            <div className="box-game-image">
                                <img src={require("../../image/fb.jpg")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ title: "ThirdPartySportsBook", gameId: "0", gpid: "1015" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game" style={{ margin: "0" }}>
                            <div className="box-game-image">
                                <img src={require("../../image/bti.jpg")} />
                            </div>
                            <div className="box-game-play">
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                            <div className="maintain">Đang bảo trì</div>
                        </div>
                        <div className="box-game" style={{ margin: "0" }}>
                            <div className="box-game-image">
                                <img src={require("../../image/sbov.jpg")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ title: "VirtualSports" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Sports;
