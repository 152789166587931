import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import "./game.css";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";

function Casino() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [isLoading, setLoading] = useState(false);
    const [game, setGame] = useState(null);
    const [title, setTitle] = useState(null);
    const [isShow, setShow] = useState(false);
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    }, []);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            setProfile(res.data.data);
            localStorage.setItem("profile", JSON.stringify(res.data.data));
        });
        handleOptionClick("0");
    }, []);
    const [activeOption, setActiveOption] = useState(null);
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    function launchGame(params) {
        if (profile === null) {
            navigate("/login");
            return;
        }
        if (isLoading == true) return;
        const form = {
            ...params,
            username: profile?.username
        };
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/login`, form)
            .then((res) => {
                let url = res.data.data.url;
                if (form.title == "SportsBook") {
                    url = `https:${url}&lang=vi-vn&oddstyle=MY&theme=sbo&oddsmode=double&device=d`;
                } else if (form.title == "Casino") {
                    url = `https:${url}&locale=vi-vn&device=d&loginMode=1&productId=0`;
                } else if (form.title == "VirtualSports") {
                    url = `https:${url}&lang=vi-vn`;
                } else if (form.title == "SeamlessGame") {
                    url = `https:${url}&gpid=${params.gpid}&gameid=${params.gameId}&lang=vi-vn&device=d`;
                } else if (form.title == "ThirdPartySportsBook") {
                    url = `https:${url}&gpid=${params.gpid}&gameid=0&lang=vi-vn&device=d`;
                }
                window.location.href = url;
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.data.data.tokenVerificationData.access == false) {
                    swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error");
                    return;
                }
                //swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
            });
    }
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);
    return (
        <>
            <Header profile={profile} />
            {isLoading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="bg-live">
                <div className="container">
                    <div className="tabframe" style={{ height: "auto" }}>
                        <div className={activeOption === "0" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("0")}>
                            <img src={require("../../image/common/icon-wm.png")} />
                            <p>WM CASINO</p>
                        </div>
                        <div className={activeOption === "1" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("1")}>
                            <img src={require("../../image/common/icon-mg.png")} />
                            <p>MG CASINO</p>
                        </div>
                        <div className={activeOption === "2" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("2")}>
                            <img src={require("../../image/common/icon-dg.png")} />
                            <p>DG CASINO</p>
                        </div>
                        <div className={activeOption === "3" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("3")}>
                            <img src={require("../../image/common/icon-awc.png")} />
                            <p>SEXY CASINO</p>
                        </div>
                        <div className={activeOption === "4" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("4")}>
                            <img src={require("../../image/common/icon-via.png")} />
                            <p>VIA CASINO</p>
                        </div>
                        <div className={activeOption === "5" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("5")}>
                            <img src={require("../../image/common/icon-yb.png")} />
                            <p>YEEBET CASINO</p>
                        </div>
                        <div className={activeOption === "6" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("6")} style={{ marginTop: "10px" }}>
                            <img src={require("../../image/common/icon-gd.png")} />
                            <p>GD88 CASINO</p>
                        </div>
                        <div className={activeOption === "7" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("7")} style={{ marginTop: "10px" }}>
                            <img src={require("../../image/common/icon-miki.png")} />
                            <p>MikiWorld CASINO</p>
                        </div>
                    </div>
                    <div className="tab_item">
                        <div className={activeOption === "0" ? "tabitem active" : "tabitem"}>
                            <div
                                className="content-box"
                                style={{
                                    background: "#000000a1",
                                    borderRadius: "20px",
                                    padding: "30px"
                                }}>
                                <img className="live-provider-img" src={require("../../image/common/wm-title.png")} />
                                <img className="icon-pic" src={require("../../image/common/icon-pic.png")} />
                                <a class="play-btn" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "0" })}>
                                    PLAY NOW
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/common/wm.png")} />
                            </div>
                        </div>
                        <div className={activeOption === "1" ? "tabitem active" : "tabitem"}>
                            <div
                                className="content-box"
                                style={{
                                    background: "#000000a1",
                                    borderRadius: "20px",
                                    padding: "30px"
                                }}>
                                <img className="live-provider-img" src={require("../../image/common/smglive-title.png")} />
                                <img className="icon-pic" src={require("../../image/common/icon-pic.png")} />
                                <a class="play-btn" style={{ opacity: "0.6" }}>
                                    BẢO TRÌ
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/common/smglive.png")} />
                            </div>
                        </div>
                        <div className={activeOption === "2" ? "tabitem active" : "tabitem"}>
                            <div
                                className="content-box"
                                style={{
                                    background: "#000000a1",
                                    borderRadius: "20px",
                                    padding: "30px"
                                }}>
                                <img className="live-provider-img" src={require("../../image/common/dg-title.png")} />
                                <img className="icon-pic" src={require("../../image/common/icon-pic.png")} />
                                <a class="play-btn" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1030" })}>
                                    PLAY NOW
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/common/dg.png")} />
                            </div>
                        </div>
                        <div className={activeOption === "3" ? "tabitem active" : "tabitem"}>
                            <div
                                className="content-box"
                                style={{
                                    background: "#000000a1",
                                    borderRadius: "20px",
                                    padding: "30px"
                                }}>
                                <img className="live-provider-img" src={require("../../image/common/awc-title.png")} />
                                <img className="icon-pic" src={require("../../image/common/icon-pic.png")} />
                                <a class="play-btn" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "7" })}>
                                    PLAY NOW
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/common/awc.png")} />
                            </div>
                        </div>
                        <div className={activeOption === "4" ? "tabitem active" : "tabitem"}>
                            <div
                                className="content-box"
                                style={{
                                    background: "#000000a1",
                                    borderRadius: "20px",
                                    padding: "30px"
                                }}>
                                <img className="live-provider-img" src={require("../../image/common/via-title.png")} />
                                <img className="icon-pic" src={require("../../image/common/icon-pic.png")} />
                                <a class="play-btn" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1064" })}>
                                    PLAY NOW
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/common/evo.png")} />
                            </div>
                        </div>
                        <div className={activeOption === "5" ? "tabitem active" : "tabitem"}>
                            <div
                                className="content-box"
                                style={{
                                    background: "#000000a1",
                                    borderRadius: "20px",
                                    padding: "30px"
                                }}>
                                <img className="live-provider-img" src={require("../../image/common/yb-title.png")} />
                                <img className="icon-pic" src={require("../../image/common/icon-pic.png")} />
                                <a class="play-btn" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1019" })}>
                                    PLAY NOW
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/common/agin.png")} />
                            </div>
                        </div>
                        <div className={activeOption === "6" ? "tabitem active" : "tabitem"}>
                            <div
                                className="content-box"
                                style={{
                                    background: "#000000a1",
                                    borderRadius: "20px",
                                    padding: "30px"
                                }}>
                                <img className="live-provider-img" src={require("../../image/common/gd-title.png")} />
                                <img className="icon-pic" src={require("../../image/common/icon-pic.png")} />
                                <a class="play-btn" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "33" })}>
                                    PLAY NOW
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/common/gd.png")} />
                            </div>
                        </div>
                        <div className={activeOption === "7" ? "tabitem active" : "tabitem"}>
                            <div
                                className="content-box"
                                style={{
                                    background: "#000000a1",
                                    borderRadius: "20px",
                                    padding: "30px"
                                }}>
                                <img className="live-provider-img" src={require("../../image/common/miki-title.png")} style={{height:"100px"}}/>
                                <img className="icon-pic" src={require("../../image/common/icon-pic.png")} />
                                <a class="play-btn" onClick={() => launchGame({ title: "SeamlessGame", gameId: "0", gpid: "1045" })}>
                                    PLAY NOW
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/common/miki.png")} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Casino;
