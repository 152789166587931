import { useEffect, useState, useRef } from "react";
import axios from "axios";
import swal from "sweetalert";
import "./game.css";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { allGames } from "../../allgames";

function Fishing() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [isLoading, setLoading] = useState(false);
    const [game, setGame] = useState(null);
    const [title, setTitle] = useState(null);
    const [isShow, setShow] = useState(false);
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        let width = (canvas.width = window.innerWidth);
        let height = (canvas.height = window.innerHeight);
        const particleCount = 100;
        const particles = [];
        const g = 10;
        const img = new Image();
        img.src = "/images/bubble.png";

        function init() {
            for (let i = 0; i < particleCount; i++) {
                createParticle();
            }
        }

        function createParticle() {
            const newParticle = new Particle();
            newParticle.initialize();
            particles.push(newParticle);
        }

        function Particle() {
            this.initialize = function () {
                this.x = Math.random() * width;
                this.y = Math.random() * height + height;
                this.v = 5 + Math.random() * 5;
                this.s = 5 + Math.random() * 40;
            };

            this.update = function () {
                this.x += Math.sin(this.s);
                this.s -= 0.1;
                if (this.s < 0) {
                    this.s = 0;
                }
                this.y -= this.v * 0.35;
                if (this.isOutOfBounds()) {
                    this.initialize();
                }
            };

            this.draw = function () {
                ctx.drawImage(img, this.x, this.y, this.s, this.s);
            };

            this.isOutOfBounds = function () {
                return this.x < 0 || this.x > width || this.y < 0 || this.y > height;
            };
        }

        function render() {
            ctx.clearRect(0, 0, width, height);
            for (let i = 0; i < particles.length; i++) {
                particles[i].update();
                particles[i].draw();
            }
            requestAnimationFrame(render);
        }

        function resize() {
            width = canvas.width = window.innerWidth;
            height = canvas.height = window.innerHeight;
        }

        window.addEventListener("resize", resize);

        init();
        render();

        // Cleanup function
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, []);
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    }, []);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            setProfile(res.data.data);
            localStorage.setItem("profile", JSON.stringify(res.data.data));
        });
    }, []);
    function launchGame(params) {
        if (profile === null) {
            navigate("/login");
            return;
        }
        if (isLoading == true) return;
        const form = {
            ...params,
            username: profile?.username
        };
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/login`, form)
            .then((res) => {
                let url = res.data.data.url;
                if (form.title == "SportsBook") {
                    url = `https:${url}&lang=vi-vn&oddstyle=MY&theme=sbo&oddsmode=double&device=d`;
                } else if (form.title == "Casino") {
                    url = `https:${url}&locale=vi-vn&device=d&loginMode=1&productId=0`;
                } else if (form.title == "VirtualSports") {
                    url = `https:${url}&lang=vi-vn`;
                } else if (form.title == "SeamlessGame") {
                    url = `https:${url}&gpid=${params.gpid}&gameid=${params.gameId}&lang=vi-vn&device=d`;
                } else if (form.title == "ThirdPartySportsBook") {
                    url = `https:${url}&gpid=${params.gpid}&gameid=0&lang=vi-vn&device=d`;
                }
                window.location.href = url;
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.data.data.tokenVerificationData.access == false) {
                    swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error");
                    return;
                }
                //swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
            });
    }
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);

    return (
        <>
            <Header profile={profile} />
            {isLoading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="bg-fishing">
                <div className="container" style={{ position: "relative", zIndex: "2" }}>
                    <center>
                        <img src={require("../../image/common/title4.png")} />
                    </center>
                    <div className="grid-6">
                        {allGames
                            .filter((game) => game.gameType === 6 && game.newGameType === 203 && game.supportedCurrencies.includes("VND"))
                            .map((item) => (
                                <>
                                    {item.gameInfos[0].language != "ZH_CN" && (
                                        <div className="gameslot-item" onClick={() => launchGame({ title: "SeamlessGame", gameId: item.gameID, gpid: item.gameProviderId })}>
                                            <img alt={item.gameInfos[0].gameName} src={item.gameInfos[0].gameIconUrl} />
                                            <div className="gameslot-name">{item.gameInfos[0].gameName}</div>
                                        </div>
                                    )}
                                </>
                            ))}
                    </div>
                </div>
                <canvas ref={canvasRef} id="bubble" />
            </div>
        </>
    );
}
export default Fishing;
